<template>
  <b-row>
    <b-col cols="8">
      <b-table-filter
        :items="items"
        :fields="fields"
        title="Danh Sách Cuộc Hẹn"
      >
        <template #filterAdvanced>
          <b-row>
            <b-col cols="4">
              <b-row no-gutters>
                <b-col>
                  <div class="ft-class-list-opt">
                    <b-form-group
                      label="Từ Ngày"
                    >
                      <b-form-datepicker
                        v-model="filterDateFrom"
                        @input="columnFilter('tu_ngay')"
                        placeholder="Từ Ngày"
                        :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                        :date-disabled-fn="dateDisabled"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col>
                  <div class="ft-class-list-opt">
                    <b-form-group
                      label="Đến Ngày"
                    >
                      <b-form-datepicker
                        v-model="filterDateTo"
                        @input="columnFilter('den_ngay')"
                        placeholder="Đến Ngày"
                        :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                        :date-disabled-fn="dateDisabled"
                      />
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <template #filterTable>
          <td style="width: 15%">
            <b-form-group>
              <b-form-input size="sm"
                class="ft-search-form-them-khoa-hoc"
                placeholder="Tìm Người Hẹn"
                @keyup="columnFilter('course')"
                v-model="filterKhoaHoc"
              />
            </b-form-group>
          </td>
          <td style="width: 15%">
            <b-form-group>
              <b-form-input size="sm"
                class="ft-search-form-them-khoa-hoc"
                placeholder="Tìm Nhân Viên Tạo"
                @keyup="columnFilter('class_name')"
                v-model="filterLopHoc"
              />
            </b-form-group>
          </td>
          <td style="width: 15%">
            <b-form-group>
              <b-form-input size="sm"
                class="ft-search-form-them-khoa-hoc"
                placeholder="Tìm Theo Mã Đơn"
                @keyup="columnFilter('teacher')"
                v-model="filterGiaoVien"
              />
            </b-form-group>
          </td><td></td>
          <td>
            <b-form-group>
              <b-form-datepicker  size="sm"
                v-model="filterDateFrom"
                @input="columnFilter('tu_ngay')"
                placeholder="Từ Ngày"
                :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
              />
            </b-form-group>
          </td>
          <td>
            <b-form-group>
              <b-form-datepicker size="sm"
                v-model="filterDateTo"
                @input="columnFilter('den_ngay')"
                placeholder="Đến Ngày"
                :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                :date-disabled-fn="dateDisabled"
              />
            </b-form-group>
          </td>
        </template>
      </b-table-filter>
    </b-col>
  </b-row>

</template>

<script>
import {
  BFormGroup, BButton, BCol, BRow, BFormInput, BFormDatepicker, BCardBody, BTable, BCardSubTitle, BContainer, BCardTitle, BPagination, BFormSelect, BCard, BBadge, BCardHeader,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import BTableFilter from '@/@core/components/datatable/BTableFilter.vue'
import VueSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BCol,
    BRow,
    VueSelect,
    FeatherIcon,
    BTableFilter,
    BContainer,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'Giờ',
          key: 'hour',
        },
        {
          label: 'Ngày',
          key: 'date',
        },
        {
          label: 'Người Hẹn',
          key: 'datePerson',
        },
        {
          label: 'Người Tạo Cuộc Hẹn',
          key: 'creator',
        },
        {
          label: 'Nội Dung Cuộc Hẹn',
          key: 'note',
        },
        {
          label: 'Đơn Hàng',
          key: 'orderCode',
        },
        {
          label: 'Tình Trạng',
          key: 'status',
        },
        {
          label: '',
          disabledEditBtn: true,
          key: 'act',
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterKhoaHoc: null,
      filterLopHoc: null,
      filterGiaoVien: null,
      filterTeacherAt: null,
      filter: null,
      filterOn: [], // danh sách cột
      count: 0,
      filterDateFrom: null,
      filterDateTo: null,
      selected: [],
      items: [
        {
          id: 4,
          hour: '20:20:20',
          date: '27/07/2022',
          datePerson: 'Nguyễn Văn A',
          creator: 'Danh Sinh',
          note: 'Cuộc Hẹn',
          orderCode: 111,
          status: true,
        },
      ],
    }
  },
  mounted() {
    document.title = 'Quản lý Lớp'
  },
  computed: {
    rowsLength() {
      return this.items.length;
    },
    rows() {
      if (this.selectedRows) {
        return this.items.filter(item => !this.selectedRows.includes(item))
      // eslint-disable-next-line no-else-return
      } else if (this.filterDateFrom && this.filterDateTo) {
        const start = Math.round(new Date(this.filterDateFrom).getTime()/1000)
        const end = Math.round(new Date(this.filterDateTo).getTime()/1000)
        return this.items.filter(item => {
          return item.start_date >= start && item.end_date <= end
        })
      }
      return this.items
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },
  methods: {
    filterAdvanced(t, $event) {
    },
    dateDisabled(ymd, date) {
      const day = date.getDate()
      return day < new Date(new Date(this.filterDateFrom).getDate())
    },
    columnFilter(t) {
      this.filterOn = [t]
      // eslint-disable-next-line default-case
      switch (t) {
        case 'course' :
        {
          this.filter = this.filterKhoaHoc
        }
        break;
        case 'class_name' :
        {
          this.filter = this.filterLopHoc
        }
        break;
        case 'teacher' :
        {
          this.filter = this.filterGiaoVien
        }
        break;
        case 'teacher_at' :
        {
          this.filter = this.filterTeacherAt
        }
        break;
      }
    },
    onFiltered(filteredItems) {
      // console.log('onFilter', filteredItems)
      // this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowSelected(rows) {
      this.count = rows.length
      this.selected = rows
    },
    onSubmit() {
      this.$emit('on-submit', this.selected)
    },
    onRemove(id) {
      this.items = this.items.filter((item, k) => k !== id)
    },
    onColumnFilter(params) {
      console.log('params', params)
      this.rows = fakeData
      // params.columnFilters - filter values for each column in the following format:
      // {field1: 'filterTerm', field3: 'filterTerm2')
      return []
    },
  },
}
</script>

<style>
.ft-class-list-opt{
  margin-right: 10px;
}
</style>